@import 'bootstrap';
@import 'app';

body * {
  //border: 1px solid red;
}
.search-result-card {
  min-height: 50px;
  background-color: $gray-100;
  padding: 1rem;
  margin-top: 0.2rem;
}

a.clickable-search-term {
  color: unset;
  text-decoration-line: underline;
  text-decoration-color: $gray-500;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: $gray-500;
  cursor: pointer;

  &:hover {
    color: darken($link-color, 50%);
  }
}

mark {
  color: unset;
  background-color: rgba($white, .9);
  padding: 0 0.08em;
}

.ais-SearchBox-loadingIndicator {
  position: absolute;
  top: 21px;
  right: 60px;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.6rem + 1.2vw);
  }
}

.ais-RefinementList-searchBox {
  max-width: 250px;
}
