@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family-sans-serif: 'Work Sans', sans-serif;
$headings-font-family: 'Libre Baskerville', serif;

$primary: #EA5147;
$secondary: #323954;
$gray-100: #e2e5e9;
$gray-200: #c5cad3;
$gray-300: #a8b0bd;
$gray-400: #8b95a7;
$gray-500: #6e7b91;
$gray-600: #586274;
$gray-700: #424a57;
$gray-800: #2c313a;
$gray-900: #16191d;
$black: #0b0c0e;

$text-muted: $gray-500;

$link-decoration: underline;

$input-focus-border-color: $primary;
$input-btn-focus-box-shadow:  0 0 15px 0.2em rgba($input-focus-border-color, 0.1);
$input-btn-padding-x-sm: 0.9rem;
$input-btn-padding-y-sm: 0.4rem;

$input-padding-x: 1.3rem;
$input-padding-y: .8rem;


$headings-font-weight: 600;

$border-radius: 0px;

$enable-rounded: false;
$enable-responsive-font-sizes: true;

@import "~bootstrap/scss/bootstrap";

a {
  text-decoration: underline !important;
}

.text-dark-2 {
  color: $gray-600;
}

.navbar-toggler-icon {
  background-size: 75% 75%;
}

// For loading indicator svg
.stroke-primary {
  stroke: $primary;
}

.border-width-2 {
  border-width: 2px;
}

